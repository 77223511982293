@media only screen and (max-width: 600px){
    body{
        overflow: -moz-scrollbars-vertical;
        overflow-x: hidden;
    }
    
    .aboutus1-bg{
        margin-top: 55%;
        position: absolute;
        width: 41%;
    }
    
    .aboutus1-picture{
        position: absolute;
        width: 85%;
        margin-left: 10%;
        margin-top: 60%;
    }
    
    .aboutus1-title{
        text-align: center;
        margin-left: 0%;
        margin-top: 10%;
    }
    
    .aboutus-title-first{
        font-family: 'LinotteSemiBold';
        font-size: 6vw;
        margin-bottom: -2%;
    }
    
    .aboutus-title-second{
        font-family:'BalooBhaina';
        font-size: 14vw;
    }
    
    .aboutus1-sub-title{
        text-align: center;
        margin-left: 0%;
        margin-top: 120%;
        color: #646464;
        font-family: 'LinotteHeavy';
        font-size: 10vw;
    }
    
    .aboutus1-description-text{
        padding: 5%;
        text-align: center;
        margin-left: 0%;
        font-family: 'LinotteSemiBold';
        color:#AAAAAA;
        font-size: 4.5vw;
        width: 100%;
    }
    
    .pacman{
        text-align: center;
        width: 60%;
        margin-top: 265%;
        margin-left:20%;
    }
    
    .aboutus2-sub-title{
        text-align: center;
        margin-left: 10%;
        margin-bottom: 7.5%;
        color: #646464;
        font-family: 'LinotteHeavy';
        font-size: 4vw;
        font-size: 10vw;
    }
    
    .aboutus2-description-text{
        text-align: center;
        margin-left: 17.5%;
        font-family: 'LinotteSemiBold';
        color:#AAAAAA;
        font-size: 4.5vw;
        width: 80%;
    }
    
    .aboutus2-bg{
        width: 70%;
        margin-left: 32%;
        margin-top: -100%;
    }
    
    .aboutus2-picture{
        position: relative;
        width:95%;
        margin-left: 5%;
        margin-top: 160%;
    }
    
    #aboutus1-left {
        position: absolute;
        width: 100vw;
        margin-left:-2%;
        height:3vw;
    }
    
    #aboutus1-right {
        position: absolute;
        width: 100vw;
        height:3vw;
        margin-top:6%;
    }
    
    #aboutus2-left {
        position: absolute;
        width: 95vw;
        margin-left:-5%;
        height:2vw;
        margin-top: 25%;
    }
    
    #aboutus2-right {
        position: relative;
        width: 95vw;
        margin-top: -20%;
        margin-left: 3.1%;
    }

    #aboutus {
        background-color: #FFF;
        height:  550vw;
      }
}
 
  