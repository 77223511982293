#privacy{
    width: 70%;
    margin-left: 10%;
    margin-bottom: 10%;
}

.title{
    font-family: 'LinotteHeavy';
    font-size: 2vw;
    color: #71C406;
    margin-bottom: 2%;
    margin-left: 5%;
    margin-top: 7.5%;
}

.description{
    font-size: 1.5vw;
    font-family: 'LinotteSemiBold';
    color: #bac2ac;
}

.information ul{
    margin-top: 3%;
    font-family: 'LinotteSemibold';
}

.service ul{
    margin-top: 2%;
}