@media only screen and (max-width: 600px){
    .navbar-header {
        position: fixed;
        z-index: 1000;
        background-color: #71C406 !important;
        padding-right: 5vw;
        min-height: 12.5vh;
    }

    .navigation-list {
        font-size: 2.5vh;
        font-weight: bold;
        font-family: 'BalooBhaina';
        color: white !important;
    }

    .navbar-toggler {
        border-color: white !important;
    }
    
    #logo {
        position: absolute;
        height: 15vw;
        margin-top: -7.5%;
        margin-left: 3%;
        transition: height 0.15s ease-out;
        left: 0;
    }
    
    .store-button {
        background-color: #62BB0C;
        border-radius: 5px;
        box-shadow: 0 0 4px #868686;
        max-width: 5vw;
    }
    
    .store-button a {
        color: #fff !important;
    }
    
    .language-button {
        max-width: 2.5vw;
    }
    
    .language-button a
    {
        font-size: 2vw;
    }
}