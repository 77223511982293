@media only screen and (max-width: 600px) {
  #contact {
    height: 485vw;
    background-color: #FFF;
  }

  .contact-title {
    margin-top: -15%;
    margin-left: 0%;
  }

  .contact-title-first {
    font-family: 'LinotteSemiBold';
    font-size: 8.5vw;
    margin-bottom: -2%;
    text-align: center;
  }

  .contact-title-second {
    font-family: 'BalooBhaina';
    font-size: 13vw;
    text-align: center;
  }

  .contact-description-title {
    text-align: center;
    position: absolute;
    font-family: 'LinotteHeavy';
    color: #A0A0A0;
    font-size: 5.5vw;
    margin-left: 5%;
    line-height: 1.25;
    width: 150%;
    margin-top: 270%;
  }

  .contact-location-icon {
    width: 20%;
    margin-left: 70%;
    padding-top: 4%;
  }

  .contact-location-title {
    font-size: 8vw;
    font-family: 'LinotteHeavy';
    margin-left: 30%;
    margin-top: 50%;
  }

  .contact-location-description {
    text-align: center;
    font-size: 5vw;
    margin-left: -5%;
    margin-top: 5%;
    color: #A8A8A8;
    font-family: 'LinotteSemiBold';
    width: 150%;
  }

  .contact-map {
    transition: .25s;
    border: 1vw solid #71C406;
    margin-left: 10%;
    margin-top: 140%;
    border-color: #71C406;
    width: 150%;
    border-radius: 15px;
  }

  .contact-map:hover {
    transition: .25s;
    border: 1 solid #71C406;
    border-width: 0.2vw;
    border-color: #71C406;
    cursor: pointer;
  }

  .contact-phone-image {
    width: 100%;
  }

  .form-bg {
    background-color: #fff;
    box-shadow: 0 0 2px rgb(146, 146, 146);
    height: 50vw;
  }

  .send-button {
    width: 80%;
    font-size: 5vw !important;
    margin-top: -20%;
    margin-left: 0%;
    background-color: #A5F458 !important;
    border-radius: 2vw !important;
    border-color: #707070 !important;
    font-family: 'LinotteHeavy';
    color: #fff;
  }

  .form-box {
    color: #a0a0a0 !important;
    margin-top: 70% !important;
  }

  .box {
    background-color: #F2F2F2 !important;
    opacity: .5 !important;
    font-family: 'LinotteSemibold' !important;
    font-size: 4.5vw !important;
    border-color: #C6C6C6 !important;
    margin-bottom: 20%;
    max-width: 80vw;
  }

  .box::placeholder {
    color: #999999 !important;
    opacity: 1 !important;
  }

  .messagebox {
    margin-top: -18.5%;
    max-height: 35vw;
  }

  .firstnamebox {
    max-width: 150vw !important;
  }

  .secondnamebox {
    max-width: 150vw!important;
    margin-left: 0%;
  }

  #contact-description {
    position: absolute;
    width: 50%;
    height: 3vw;
    margin-left: 7%;
  }

  #contact-form {
    text-align: center;
    position: absolute;
    width: 80%;
    height: 3vw;
    margin-left: 10%;
    margin-top: 170%;
  }

  #contact-phone-image {
    position: absolute;
    width: 75%;
    height: 5vw;
    margin-left: 15%;
    margin-top: 290%;
  }

  #contact-location-left {
    position: absolute;
    margin-top: 5%;
    width: 100%;
    height: 4vw;
  }

  #contact-location-right {
    position: absolute;
    margin-left: 16%;
    margin-top: -10%;
    width: 100%;
  }

  .businessmap {
    height: 20vw;
    width: 20vw;
  }
}