.contact-title {
  margin-top: -15%;
  margin-left: 10%;
}

.contact-title-first {
  font-family: 'LinotteSemiBold';
  font-size: 3vw;
  margin-bottom: -2%;
}

.contact-title-second {
  font-family: 'BalooBhaina';
  font-size: 6vw;
}

.contact-description-title {
  font-family: 'LinotteHeavy';
  color: #A0A0A0;
  font-size: 1.75vw;
  margin-left: 10%;
  line-height: 1.25;
}

.contact-location-icon {
  width: 5%;
  margin-left: 7%;
  padding-top: 4%;
}

.contact-location-title {
  font-size: 1.5vw;
  font-family: 'LinotteHeavy';
  margin-left: 4%;
}

.contact-location-description {
  padding-top: .5%;
  margin-left: 4%;
  font-size: 1.1vw;
  color: #A8A8A8;
  font-family: 'LinotteSemiBold';
}

.contact-map {
  transition: .25s;
  border: 0 solid white;
  margin-left: 10%;
  margin-top: 7%;
  width: 67%;
}

.contact-map:hover {
  transition: .25s;
  border: solid;
  border-width: 0.2vw;
  border-radius: 15px;
  border-color: #71C406;
  cursor: pointer;
}


.contact-phone-image {
  width: 100%;
}

.form-bg {
  background-color: #fff;
  box-shadow: 0 0 2px rgb(146, 146, 146);
  height: 28vw;
}

.send-button {
  width: 30%;
  font-size: 1.5vw !important;
  margin-top: -2%;
  background-color: #A5F458 !important;
  border-radius: 2vw !important;
  border-color: #707070 !important;
  font-family: 'LinotteHeavy';
  color: #fff;
}

.form-box {
  color: #a0a0a0 !important;
  margin-top: 1%;
}

.box {
  background-color: #F2F2F2 !important;
  opacity: .5 !important;
  font-family: 'LinotteSemibold' !important;
  font-size: 1.5vw !important;
  border-color: #C6C6C6 !important;
  margin-bottom: 7.5%;
}

.box::placeholder {
  color: #999999 !important;
  opacity: 1 !important;
}

.messagebox {
  max-height: 20vw;
}

.firstnamebox{
  max-width: 15vw;
}

.secondnamebox{
  max-width: 15vw;
  margin-left: -33.5%;
}

#contact-description {
  position: relative;
  width: 30%;
  height: 3vw;
  margin-left: 7%;
}

#contact-form {
  position: relative;
  width: 55%;
  height: 3vw;
  margin-left: 37%;
}

#contact-phone-image {
  position: relative;
  width: 20%;
  height: 5vw;
  margin-left: 75%;
  margin-top: -5%;
}

#contact-location-left {
  position: relative;
  margin-top: 5%;
  width: 40vw;
  height: 4vw;
}

#contact-location-right {
  position: relative;
  margin-left: 16%;
  margin-top: -10%;
  width: 40vw;
}

.businessmap {
  height: 20vw;
  width: 20vw;
}