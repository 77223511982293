body{
  overflow: -moz-scrollbars-vertical;
  overflow-x: hidden;
}

.aboutus1-bg{
  width: 25%;
}

.aboutus1-picture{
  width: 35%;
  margin-left: -21%;
}

.aboutus1-title{
  margin-left: 42%;
}

.aboutus-title-first{
  font-family: 'LinotteSemiBold';
  font-size: 3vw;
  margin-bottom: -2%;
}

.aboutus-title-second{
  font-family:'BalooBhaina';
  font-size: 6vw;
}

.aboutus1-sub-title{
  margin-left: 40%;
  margin-top: 2%;
  color: #646464;
  font-family: 'LinotteHeavy';
  font-size: 4vw;
}

.aboutus1-description-text{
  margin-left: 40%;
  font-family: 'LinotteSemiBold';
  color:#AAAAAA;
  font-size: 2vw;
  margin-top: 2%;
  width: 50%;

}

.pacman{
  width: 60%;
  margin-top: 53%;
  margin-left:20%;
}

.aboutus2-sub-title{
  margin-left: 10%;
  margin-bottom: 3%;
  color: #646464;
  font-family: 'LinotteHeavy';
  font-size: 4vw;
}

.aboutus2-description-text{
  margin-left: 10%;
  font-family: 'LinotteSemiBold';
  color:#AAAAAA;
  font-size: 2vw;
  width: 50%;
}

.aboutus2-bg{
  width: 34%;
  margin-left: -31%;
}

.aboutus2-picture{
  position: relative;
  width: 35%;
  margin-left: 62%;
  margin-top: -15%;
}

#aboutus1-left {
  position: relative;
  width: 100vw;
  margin-left:-2%;
  height:3vw;
}

#aboutus1-right {
  position: relative;
  width: 100vw;
  height:3vw;
  margin-top:6%;
}

#aboutus2-left {
  position: relative;
  width: 95vw;
  margin-left:-5%;
  height:2vw;
  margin-top: 25%;
}

#aboutus2-right {
  position: relative;
  width: 97vw;
  margin-top: -20%;
  margin-left: 4%;
}

#aboutus {
  background-color: #FFF;
  height:  160vw;
}
