@media only screen and (max-width: 600px){
    
    #home-left {
        position: absolute;
        margin-left: 0%;
        width: 100vw;
    }
    
    #home-right {
        position: absolute;
        margin-right: 0%;
        width: 100vw;
    }    
    
    .home-page {
        height: 170vw;
        margin-top: 0%;
    }
    
    .home-welcome-text {
        color: #FF4040;
        font-family: 'JumpsHigher';
        font-size: 6vw;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #FF4040;
        letter-spacing: .25vw;
        margin-bottom: .25rem;
        margin-top: 10%;
        text-align: center;
    }
    
    .home-title-text {
        font-family: 'BalooBhaina';
        color: #262626;
        line-height: 120%;
        font-size: 9vw;
        text-align: center;
        margin-top: 5%;
    }
    
    .home-description-text {
        font-family: 'LinotteSemiBold';
        color: #7A7A7A;
        line-height: 120%;
        font-size: 4vw;
        margin-top: 5%;
        text-align: center;
    }
    
    .home-image {
        margin-top: 90%;
        margin-left: 5%;
        width: 95%;
    }
    
    .home-alien {
        width: 10%;
        margin-left: 2%;
        margin-bottom: 2%;
    }
    
    .home-get-started {
        margin-top: 2%;
        margin-left: 31%;
        width: 35%;
    }
    
    .home-get-started-hand {
        margin-top: 12.5%;
        margin-left: -6%;
        width: 10%;
    }

}