.Sticky-Header-Navigation {
    position: sticky;
    top: 0;
    margin-top: 50px;
    z-index: 1000;
    list-style: none;
    display: flex;
    flex: 1;
    justify-content: end;
    flex-direction: row;
    align-items: center;
    height: 5vw;
    background-color: white;
    padding-right: 5vw;
}

.Sticky-Header-Navigation li {
    width: 7.5vw;
    margin-left: 1vw;
    margin-right: 1vw;
    font-family: 'BalooBhaina';
    font-size: 1.25vw;
    text-align: center;
}

a:link,
a:visited,
a:hover,
a:active {
    text-decoration: none;
    color: #62BB0C;
}

#logo {
    position: absolute;
    height: 7.5vw;
    margin-left: 7.5%;
    transition: height 0.15s ease-out;
    left: 0;
}

.scrollspy .active {
    /* background-color: green; */
    font-family: 'Minecraft';
    border: #fff;
    border-width: 1vw;
    letter-spacing: .1vw;
    font-size: 1vw;
    margin-top: .1%;
}

.scrollspy {
    background: fff;
    height: 7vw;
    position: sticky;
    position: -webkit-sticky;
    top: -2px;
}

.scrollspy[stuck] {
    box-shadow: 0 0 16px #868686;
    background-color: #71C406;
    color: #fff;
}

.scrollspy a{
    margin-top: -7.5%;
}

.scrollspy[stuck] li a {
    color: #fff;
}

.scrollspy[stuck] #logo {
    height: 5vw;
    transition: height 0.15s ease-out;
}

.scrollspy[stuck] .store-button {
    background-color: #fff;
}

.scrollspy[stuck] .store-button a {
    color: #62BB0C !important;
}

.scrollspy[stuck] a{
    margin-top: -5%;
}

.store-button {
    background-color: #62BB0C;
    border-radius: 5px;
    box-shadow: 0 0 4px #868686;
    max-width: 5vw;
}

.store-button a {
    color: #fff !important;
}

.language-button {
    max-width: 2.5vw;
}

.language-button a
{
    font-size: 2vw;
}