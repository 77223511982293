@font-face {
  font-family: 'BalooBhaina';
  src: url('./fonts/baloo/BalooBhai-Regular.eot');
  src: url('./fonts/baloo/BalooBhai-Regular.eot?#iefix') format('embedded-opentype'),
       url('./fonts/baloo/BalooBhai-Regular.ttf') format('truetype'),
       url('./fonts/baloo/BalooBhai-Regular.woff') format('woff'),
       url('./fonts/baloo/BalooBhai-Regular.svg#BalooBhai-Regular') format('svg');
}

@font-face {
  font-family: 'FredokaOne';
  src: url('./fonts/fredoka/FredokaOne-Regular.eot');
  src: url('./fonts/fredoka/FredokaOne-Regular.eot') format('embedded-opentype'),
       url('./fonts/fredoka/FredokaOne-Regular.ttf') format('truetype'),
       url('./fonts/fredoka/FredokaOne-Regular.woff') format('woff'),
       url('./fonts/fredoka/FredokaOne-Regular.svg#FredokaOne-Regular') format('svg');
}

@font-face {
  font-family: 'JumpsHigher';
  src: url('./fonts/jumps/JumpsHigherRegular.eot');
  src: url('./fonts/jumps/JumpsHigherRegular.eot') format('embedded-opentype'),
       url('./fonts/jumps/JumpsHigherRegular.ttf') format('truetype'),
       url('./fonts/jumps/JumpsHigherRegular.woff') format('woff'),
       url('./fonts/jumps/JumpsHigherRegular.svg#JumpsHigherRegular') format('svg');
}

@font-face {
  font-family: 'JumpsHigher';
  src: url('./fonts/jumps/JumpsHigherRegular.eot');
  src: url('./fonts/jumps/JumpsHigherRegular.eot') format('embedded-opentype'),
       url('./fonts/jumps/JumpsHigherRegular.ttf') format('truetype'),
       url('./fonts/jumps/JumpsHigherRegular.woff') format('woff'),
       url('./fonts/jumps/JumpsHigherRegular.svg#JumpsHigherRegular') format('svg');
}

@font-face {
  font-family: 'LinotteHeavy';
  src: url('./fonts/linotte/Linotte-Heavy.eot');
  src: url('./fonts/linotte/Linotte-Heavy.eot') format('embedded-opentype'),
       url('./fonts/linotte/Linotte-Heavy.ttf') format('truetype'),
       url('./fonts/linotte/Linotte-Heavy.woff') format('woff'),
       url('./fonts/linotte/Linotte-Heavy.svg#Linotte-Heavy') format('svg');
}

@font-face {
  font-family: 'LinotteSemiBold';
  src: url('./fonts/linotte/Linotte-SemiBold.eot');
  src: url('./fonts/linotte/Linotte-SemiBold.eot') format('embedded-opentype'),
       url('./fonts/linotte/Linotte-SemiBold.ttf') format('truetype'),
       url('./fonts/linotte/Linotte-SemiBold.woff') format('woff'),
       url('./fonts/linotte/Linotte-SemiBold.svg#Linotte-SemiBold') format('svg');
}

@font-face {
  font-family: 'Minecraft';
  src: url('./fonts/minecraft/Minecraft.eot');
  src: url('./fonts/minecraft/Minecraft.eot') format('embedded-opentype'),
       url('./fonts/minecraft/Minecraft.ttf') format('truetype'),
       url('./fonts/minecraft/Minecraft.woff') format('woff'),
       url('./fonts/minecraft/Minecraft.svg#Minecraft') format('svg');
}

html {
  scroll-padding-top: 350px;
}

.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

Nav .store {
  background-color: green;
}

.green-text{
  color: #71C406;
}

.red-text{
  color: #EC1C24;
}

.page {
  height: 60vw;
}

#contact {
  background-color: #FFF;
}

#ourgames {
  background-color: #FFF;
}

#games-bg {
  background-color: #F8FFEB;
  height: 38vw;
}

.footer {
  background-color: #71C406;
  box-shadow: 0 0 16px 0 #868686;
  height: 25vw;
}