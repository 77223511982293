@media only screen and (max-width: 600px){
    .footer {
        background-color: #71C406;
        box-shadow: 0 0 16px 0 #868686;
        height: 220vw;
      }
    
    #head-icon{
        position: absolute;
        width: 100%;
        height: 3vw;
    }
    
    #support{
        position: absolute;
        width: 100%;
        height: 3vw;
    }
    
    #social-media{
        position: absolute;
        width: 100%;
        height: 3vw;
    }
    
    .logo{
        width: 40%;
        margin-top: 10%;
        margin-left: 32%;
    }
    
    .support{
        text-align: center;
        margin-left: 0%;
        margin-top: 72.5%;
    }
    
    .socialmedia{
        margin-left: 0%;
        margin-top: 10%;
    }
    
    .support-title
    {
        color: #fff;
        font-size: 6vw;
        font-family: 'LinotteHeavy';
        margin-top: -10%;
    }
    
    .support-text
    {
        color: #fff;
        font-size: 5vw;
        font-family: 'LinotteSemibold';
        margin-top: 5%;
    }
    
    .socialmedia-title
    {
        position: absolute;
        text-align: center;
        color: #fff;
        font-size: 6vw;
        font-family: 'LinotteHeavy';
        margin-top: 135%;
        margin-left: 28%;
    }
    
    .social-media-icons{
        margin-top: 145%;
        margin-left: 8.5%;
    }
    
    .social-media-icon{
        width:11%; 
    }
    
    .follow-text{
        margin-top: 5%;
        margin-left: 20%;
        color:#fff;
        font-size: 10vw;
        font-family: 'LinotteHeavy';
    }
    
    .bottom-line{
        color: #fff;
        margin-top: 3%;
        width: 85%;
        margin-left: 7.5%;
        margin-top: 10%;
    }
    
    .copyright-text{
        color:#fff;
        font-family: 'LinotteSemiBold';
        margin-left: 6%;
        font-size: 6vw;
        padding-right: 4%;
        text-align: center;
    }
    
}