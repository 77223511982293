@media screen and (max-width: 600px){
  .game-carousel .slick-list .slick-track {
    display: flex;
    align-items: center;
    min-width: 38vw;
  }

  #games-bg {
    overflow-x: hidden;
    background-color: #F8FFEB;
    height: 65vw;
  }

  /* the parent */
  .slick-list {
      margin: 0 -120px; 
  }

  .games-page {
    height: 100vw;  
  }
  
  .games-title {
    text-align: center;
  }
  
  .games-title-first {
    font-family: 'LinotteSemiBold';
    font-size: 6vw;
    margin-bottom: -3%;
  }
  
  .games-title-second {
    font-family: 'BalooBhaina';
    font-size: 12vw;
  }
  
  .game-in-games-list {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .main-game {
    width: 35vw;
    margin-top: -10%;
    transition-property: height, width, filter, webkit-filter;
    transition-duration: 0.5s, 0.5s, 0.25s, 0.25s;
    transition-timing-function: ease-out;
  }
  
  .main-game-text {
    font-family: 'BalooBhaina';
    font-size: 3.5vw;
    margin-top: -5%;
    transition-property: font-size margin-top;
    transition-duration: 0.5s;
    transition-timing-function: ease-out;
    white-space: nowrap;
  }
  
  .activeGameText {
    font-family: 'BalooBhaina';
    font-size: 6vw;
    margin-top: -15%;
  
    transition-property: font-size margin-top;
    transition-duration: 0.5s;
    transition-timing-function: ease-out;
  }
  
  .activeGame {
    width: 60vw;
    transition-property: height, width, filter, webkit-filter;
    transition-duration: 0.5s, 0.5s, 0.25s, 0.25s;
    transition-timing-function: ease-out;
  }
  
  .arrow-button {
    position: absolute;
    margin-top: -7.5%;
    background-color: transparent;
    border: none !important;
    color: white;
    height: 10vw;
  }
  
  .arrow-button img {
    height: 100%;
  }
  
  .arrow-prev {
    background-color: red;
    margin-right: 5%;
    z-index: 1;
    left: 3%;
  }
  
  .arrow-next {
    background-color: red;
    margin-right: 5%;
    z-index: 1;
    right: -2%;
  }
  
  .main-game:hover {
    transition: .25s;
    webkit-filter: blur(0px); /* Chrome, Safari, Opera */
    filter: blur(0px);
  }
  
  .main-game:hover:after
  {
    transition: .15s;
    webkit-filter: blur(0px); /* Chrome, Safari, Opera */
    filter: blur(0px);
  }
}