.game-carousel .slick-list .slick-track {
  display: flex;
  align-items: center;
  min-height: 40vw;
}

.games-title {
  text-align: center;
}

.games-title-first {
  font-family: 'LinotteSemiBold';
  font-size: 3vw;
  margin-bottom: -2%;
}

.games-title-second {
  font-family: 'BalooBhaina';
  font-size: 6vw;
}

.game-in-games-list {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main-game {
  width: 18vw;
  margin-top: -25%;
  transition-property: height, width, filter, webkit-filter;
  transition-duration: 0.5s, 0.5s, 0.25s, 0.25s;
  transition-timing-function: ease-out;
}

.main-game-text {
  font-family: 'BalooBhaina';
  font-size: 2vw;
  margin-top: -5%;
  transition-property: font-size margin-top;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  white-space: nowrap;
}

.activeGameText {
  font-family: 'BalooBhaina';
  font-size: 3.25vw;
  margin-top: -15%;

  transition-property: font-size margin-top;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
}

.activeGame {
  width: 35vw;
  /* height: 25vw; */
  transition-property: height, width, filter, webkit-filter;
  transition-duration: 0.5s, 0.5s, 0.25s, 0.25s;
  transition-timing-function: ease-out;
}

.arrow-button {
  position: absolute;
  margin-top: -25%;
  background-color: transparent;
  border: none !important;
  color: white;
  height: 5vw;
}

.arrow-button img {
  height: 100%;
}

.arrow-prev {
  background-color: red;
  margin-right: 5%;
  z-index: 1;
  left: 3%;
}

.arrow-next {
  background-color: red;
  margin-right: 5%;
  z-index: 1;
  right: -2%;
}

.main-game:hover {
  transition: .25s;
  webkit-filter: blur(6px); /* Chrome, Safari, Opera */
  filter: blur(6px); 
}

.main-game:hover:after
{
  transition: .15s;
  webkit-filter: blur(0px); /* Chrome, Safari, Opera */
  filter: blur(0px);
}

.ios-store-button{
  
  
}

.main-game + .ios-store-button {
  position: absolute;
  background-color: transparent;
  color: white;
  pointer-events: none;
  visibility: hidden;
  width: 2.5% !important;
  margin-top: -2%;
  margin-left: -1.2%;
}

.activeGame + .ios-store-button {
  position: absolute;
  background-color: transparent;
  color: white;
  pointer-events: none;
  visibility: hidden;
  width: 3% !important;
  margin-top: -3.5% !important;
  margin-left: -1.5% !important;
}

@media screen and (min-width: 600px) {
  .main-game:hover + .ios-store-button { visibility: visible; }
}

@media screen and (min-width: 1000px) {
    .activeGame + .ios-store-button {
      width: 3% !important;
      margin-top: -3% !important;
      margin-left: -1.15% !important;
    }
    .main-game + .ios-store-button {
      width: 2.5% !important;
      margin-top: -1.75%;
      margin-left: -1.2%;
    }
}