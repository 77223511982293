#head-icon{
    width: 20%;
    height: 3vw;
}

#support{
    width: 40%;
    height: 3vw;
}

#social-media{
    width: 40%;
    height: 3vw;
}

.logo{
    width: 55%;
    margin-top: 15%;
    margin-left: 55%;
    cursor: pointer;
}

.support{
    margin-left: 35%;
}

.socialmedia{
    margin-left: 70%;
    margin-top: -3%;
}

.support-title
{
    color: #fff;
    font-size: 1.75vw;
    font-family: 'LinotteHeavy';
}

.support-text
{
    color: #fff;
    font-size: 1.25vw;
    font-family: 'LinotteSemibold';
    margin-top: 5%;
    cursor: pointer;
}

.socialmedia-title
{
    color: #fff;
    font-size: 1.75vw;
    font-family: 'LinotteHeavy';
}

.social-media-icons{
    margin-top: 5%;
    margin-left: -15%;
}

.social-media-icon{
    width:6%; 
    margin-right: 5%;
}

.follow-text{
    margin-top: 5%;
    margin-left: -13%;
    color:#fff;
    font-size: 3.5vw;
    font-family: 'LinotteHeavy';
}

.bottom-line{
    color: #fff;
    margin-top: 3%;
    width: 85%;
    margin-left: 7.5%;
}

.copyright-text{
    color:#fff;
    font-family: 'LinotteSemiBold';
    margin-left: 30%;
    font-size: 1.75vw;
    text-align: center;
}
