#home-left {
  position: relative;
  width: 50vw;
  margin-left: 7.5%;
  height: 3vw;
}

#home-right {
  position: relative;
  width: 50vw;
  height: 3vw;
  margin-right: 18%;
}


.home-page {
  display: flex;
  height: 43vw;
  margin-top: 7.5%;
}

.home-welcome-text {
  color: #FF4040;
  font-family: 'JumpsHigher';
  font-size: 2vw;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #FF4040;
  letter-spacing: .25vw;
  margin-bottom: .25rem;
  margin-top: 5%;
}

.home-title-text {
  font-family: 'BalooBhaina';
  color: #262626;
  line-height: 120%;
  font-size: 4vw;
}

.home-description-text {
  font-family: 'LinotteSemiBold';
  color: #7A7A7A;
  line-height: 120%;
  font-size: 1.15vw;
  margin-top: 2%;
}

.home-image {
  margin-top: -11%;
  width: 145%;
}

.home-alien {
  width: 9%;
  margin-left: 2%;
  margin-bottom: 2%;
}

.home-get-started {
  margin-top: -2%;
  margin-left: 5%;
  width: 35%;
}

.home-get-started-hand {
  margin-top: 8%;
  margin-left: -6%;
  width: 10%;
}