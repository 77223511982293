#store img{
    margin-top: 5%;
    width: 60%;
    margin-left: 21%;
}

.text{
    margin-top: 5%;
    margin-bottom: 15%;
}

.store-title{
    font-size: 10vw;
    text-align: center;
    font-family: 'BalooBhaina';
}

.store-description{
    font-family: 'LinotteSemiBold';
    font-size: 4vw;
    margin-bottom: -2%;
    text-align: center;
}